import React, { PureComponent } from "react"

import styles from "./Background.module.css";


export default class Background extends PureComponent {
  render() {
    const { children, enter } = this.props;
    return <div className={enter ? styles.enter : styles.self}>{!enter && (<div className={styles.bg} />)}{children}</div>;
  }
}
